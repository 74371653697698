import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, Switcher } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { Body } from './styles';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { v4 as uuidv4 } from 'uuid';
import { ModelRuleDataInterface } from 'store/reducers/models/types';
import {
  clearRuleModelAction,
  importingUsersAndGroupsRuleAction,
  loadGroupAction,
  loadModelRuleAction,
  loadUserModelAction,
  updateModelRuleAction,
  updateModelRuleByIdAction,
  updateRuleModelAction,
} from 'store/reducers/models/actions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
  getActiveModelRuleGroup,
  getActiveModelRuleUser,
  getAllVariables,
  getGroups,
  getModelRule,
  getModelRuleGroups,
  getModelRuleUsers,
  getUsers,
} from 'store/reducers/models/getters';
import { UsersRule } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/UsersRule';
import { GroupsRule } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/GroupsRule';
import { MainFilter } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/MainFilter';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { AllUniqueVariables } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/AllUniqueVariables';
import { ExportingUsersAndGroupsModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/ExportingUsersAndGroupsModal';
import { FileInput, FileInputLabel } from 'modules/ui/inputs/FileUploadInput/styles';
import { loadAdminLicenseAction } from 'store/reducers/adminLicenses/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { InitialDataInterface } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/types';
import { useEditRuleName } from 'components/contents/Models/hooks/useEditRuleName';
import { HeaderTitle } from 'modules/ui/title/HeaderTitle';

interface RuleModalProps {
  ruleId: string;
  projectId: string;
  modelId: string;
}

export const RuleModal: ModalComponentType<RuleModalProps> = ({ onClose, ruleId, projectId, modelId }) => {
  const dispatch = useAppDispatch();
  const { onEditRuleNameModal } = useEditRuleName(projectId);

  const { modelRulesData, loading } = useSelector(getModelRule);
  const usersRule = useSelector(getModelRuleUsers);
  const groupsRule = useSelector(getModelRuleGroups);
  const usersList = useSelector(getUsers);
  const groupsList = useSelector(getGroups);
  const activeModelRuleGroup = useSelector(getActiveModelRuleGroup);
  const activeModelRuleUser = useSelector(getActiveModelRuleUser);
  const allVariablesList = useSelector(getAllVariables);

  const modelRulesIsActive = modelRulesData?.isActive;
  const ruleName = modelRulesData?.name || '';
  const query = modelRulesData?.query || '';

  const [activeSwitcher, setActiveSwitcher] = useState(modelRulesIsActive);
  const initialDataRef = useRef<InitialDataInterface | null>(null);

  const onUpdateModelRule = (modelRuleData: ModelRuleDataInterface | null) => dispatch(updateModelRuleAction(modelRuleData));

  const onActiveSwitcher = () => {
    const newIsActive = !activeSwitcher;
    setActiveSwitcher(newIsActive);
    if (modelRulesData) {
      onUpdateModelRule({ ...modelRulesData, isActive: newIsActive });
    }
  };

  const onMainFilter = (query: string) => {
    if (modelRulesData) {
      onUpdateModelRule({ ...modelRulesData, query });
    }
  };

  useEffect(
    () => {
      if (ruleId) {
        dispatch(loadModelRuleAction({ ruleId, projectId }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ruleId],
  );

  useEffect(
    () => {
      if (usersList.length === 0) {
        dispatch(loadUserModelAction({ excludeGroups: true }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersList.length],
  );

  useEffect(
    () => {
      if (groupsList.length === 0) {
        dispatch(loadGroupAction({ excludeUsers: true }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupsList.length],
  );

  useEffect(() => {
    if (modelRulesData) {
      setActiveSwitcher(modelRulesIsActive);
      if (!initialDataRef.current) {
        initialDataRef.current = {
          usersRule,
          groupsRule,
          isActive: modelRulesIsActive,
          query,
          ruleName,
          allVariablesList,
        };
      }
    }
  }, [modelRulesData, usersRule, groupsRule, modelRulesIsActive, query, ruleName, allVariablesList]);

  useEffect(
    () => {
      return () => {
        initialDataRef.current = null;
        dispatch(clearRuleModelAction());
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const hasChanges = useCallback(() => {
    const currentData = {
      usersRule,
      groupsRule,
      isActive: activeSwitcher,
      query,
      ruleName,
      allVariablesList,
    };
    return JSON.stringify(currentData) !== JSON.stringify(initialDataRef.current);
  }, [usersRule, groupsRule, activeSwitcher, query, ruleName, allVariablesList]);

  const onSaveRule = useCallback(
    async () => {
      if (!hasChanges()) {
        return;
      }

      try {
        if (projectId) {
          const modelRuleResult = await dispatch(
            updateRuleModelAction({
              projectId,
              data: {
                modelId,
                users: usersRule,
                isActive: modelRulesIsActive || false,
                id: ruleId,
                name: ruleName || '',
                variableNames: allVariablesList,
                query,
                groups: groupsRule,
              },
            }),
          ).unwrap();

          if (modelRuleResult) {
            const { id, name, isActive } = modelRuleResult;
            dispatch(updateModelRuleByIdAction({ modelRule: { isActive, id, name } }));
            onClose();
          }
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allVariablesList,
      groupsRule,
      hasChanges,
      modelId,
      modelRulesIsActive,
      onClose,
      projectId,
      query,
      ruleId,
      ruleName,
      usersRule,
    ],
  );

  const onCloseExportModal = () => dispatch(closeModalAction('exportRuleModal'));

  const onExportRuleModal = useCallback(
    () => {
      dispatch(
        openModalTypedAction({
          Component: ExportingUsersAndGroupsModal,
          componentProps: {
            onClose: onCloseExportModal,
            onSave: onSaveRule,
            projectId,
            ruleId,
            ruleName,
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            headerText: 'Выгрузка пользователей в файл',
            disableModalPortal: true,
          },
          name: 'exportRuleModal',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseExportModal, ruleName, onSaveRule, ruleId, projectId],
  );

  const onImportUsersAndGroups = useCallback(
    async (file: File) => {
      try {
        const action = await dispatch(importingUsersAndGroupsRuleAction({ ruleId, projectId, file })).unwrap();
        if (action) {
          await dispatch(loadAdminLicenseAction());
        }
      } catch {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, ruleId],
  );

  const onImport = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      void onImportUsersAndGroups(file);
    }
  };

  const onEditName = useCallback(() => onEditRuleNameModal(ruleId, ruleName), [onEditRuleNameModal, ruleId, ruleName]);

  return (
    <FlexContainer
      position="relative"
      width="100%"
      alignItems="flex-start"
      flexDirection="column"
      height="80vh"
      padding="20px 24px 24px"
      backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
    >
      <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <FlexContainer flexDirection="column" gap="8px">
          <HeaderTitle onEdit={onEditName} title={ruleName} />
          <FlexContainer flexDirection="row" alignItems="center" gap="8px">
            <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
              Активна
            </PrimaryTextParagraph>
            <Switcher id={uuidv4()} value={activeSwitcher} onClick={onActiveSwitcher} />
          </FlexContainer>
        </FlexContainer>

        <Body>
          <FlexContainer flexDirection="column" height="100%">
            {usersRule && <UsersRule usersRule={usersRule} />}
            {groupsRule && <GroupsRule groupsRule={groupsRule} />}
          </FlexContainer>
          <FlexContainer flexDirection="column" width="100%" gap="24px">
            <MainFilter value={query} onChange={onMainFilter} />
            <AllUniqueVariables
              activeModelRuleUser={activeModelRuleUser}
              activeModelRuleGroup={activeModelRuleGroup}
              allVariablesList={allVariablesList}
            />
          </FlexContainer>
        </Body>
      </FlexContainer>
      <FlexContainer flexDirection="row" gap="13px" position="absolute" right="24px" bottom="24px">
        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Закрыть" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button text="Сохранить" iconSize="normal" needBackground={true} heightSize="normal" onClick={onSaveRule} />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer flexDirection="row" gap="12px" position="absolute" right="24px" top="13px">
        <Button text="Экспорт" iconSize="normal" heightSize="small" needBackground={true} onClick={onExportRuleModal} />
        <FileInput type="file" accept=".csv, xlsx" id="fileUpload" onChange={onImport} />
        <FileInputLabel fontSize="12px" lineHeight="12px" padding="0 10px" htmlFor="fileUpload">
          Импорт
        </FileInputLabel>
      </FlexContainer>
    </FlexContainer>
  );
};
