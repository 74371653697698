import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRLS } from 'store/reducers/projectSettings/getters';
import { RLSInterface } from 'types/types';

export const useRLS = (): { rls: RLSInterface | undefined } => {
  const rls = useSelector(getRLS);

  return useMemo(() => {
    if (!rls || !rls.isActive || rls.type === 'none' || rls.entityId === null) {
      return { rls: undefined };
    }

    return {
      rls: {
        type: rls.type,
        entityId: rls.entityId || '',
      },
    };
  }, [rls]);
};
