import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import {
  defaultSqlDataSettings,
  defaultTreeDataSettings,
  defaultTreeViewSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import React from 'react';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { TreeIncisionInterface, TreeIndicatorInterface } from 'store/reducers/visualisations/types';
import {
  onChangeIncisionPropertiesIncisionName,
  onChangeIncisionPropertiesNameValue,
  onChangeIncisionPropertiesValue,
  onChangeShowValue,
  onColorIncisionChange,
  onMoveIncision,
} from 'modules/visualisations/Tree/settings/DataTab/constants';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import { ShowValueSettings } from 'modules/settingsContainer/common/data/ShowValueSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import {
  onAddNewIncision,
  onAddNewIndicator,
  onChangeEmptyValue,
  onChangeFictionalData,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onChangeIncisionNameFromDataBase,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorFormatting,
  onChangeIndicatorName,
  onChangeIndicatorNameFromDataBase,
  onDeleteIncision,
  onDeleteIndicator,
  onLimitChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { settingsLayoutWidthSecondLevel } from 'modules/settingsContainer/SettingsLayout/constants';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';

export const DataTab = () => {
  const { dataSettings, codeEditorData, astOfVisualisation } = useGetActiveVisualisationSettings({
    defaultData: defaultTreeDataSettings,
    defaultView: defaultTreeViewSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { isRealData } = dataSettings;

  const { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(dataSettings.modelId);

  const incisionRender: MetricsRenderType<TreeIncisionInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ fieldName, name, fictionalData, id, colors, settings: { nameFromDatabase, customRequest } }, index) => {
        const onUpClick = () => onMoveIncision(dataSettings, id, 'up');
        const onDownClick = () => onMoveIncision(dataSettings, id, 'down');
        const onChangeColors = (colors: PaletteValuesByThemeType | null) => {
          onColorIncisionChange(dataSettings, colors, id);
        };

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
            onDownClick={onDownClick}
            onUpClick={onUpClick}
            onChangeColors={onChangeColors}
            colorsValue={colors}
            disableColorPicker={index !== 0}
            colorPickerType="palette"
            key={id}
          >
            <FictionalMetricField
              onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest || ''}
              disabled={!!customRequest}
              isRealData={isRealData}
              options={tableFields}
              value={{ fictionalData, fieldName }}
              onChange={({ fictionalData, fieldName }) => {
                fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
                fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
              }}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const incisionFieldSettingsRender: FieldSettingsRenderType<TreeIncisionInterface> = ({ metric: incision }) => {
    const {
      id,
      settings: {
        emptyValues: { isEmptyValue, value },
        nameFromDatabase,
      },
      propertiesIncisionValue,
      propertiesIncisionNameValue,
      propertiesIncisionName,
    } = incision;

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeEmptyValue(dataSettings, value, id)}
        />
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIncisionNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <PropertiesSettings
          rightPositionModal={settingsLayoutWidthSecondLevel}
          title="Свойства значения"
          value={propertiesIncisionNameValue}
          onChange={(value) => onChangeIncisionPropertiesNameValue(dataSettings, value, id)}
          isMainContainerColorSettings
          disabledOpacity
          disabledPadding
          disabledFontColorByBlock
          disabledBackgroundColorBy
          disabledLineHeight
          disabledUnderline
          disabledLetterSpacing
        />
        <PropertiesSettings
          rightPositionModal={settingsLayoutWidthSecondLevel}
          title="Свойства метки разреза"
          value={propertiesIncisionName}
          onChange={(value) => onChangeIncisionPropertiesIncisionName(dataSettings, value, id)}
          isMainContainerColorSettings
          disabledOpacity
          disabledPadding
          disabledFontColorByBlock
          disabledLineHeight
          disabledBackgroundColorBy
          disabledUnderline
          disabledLetterSpacing
        />
        <PropertiesSettings
          rightPositionModal={settingsLayoutWidthSecondLevel}
          title="Свойства названия разреза"
          value={propertiesIncisionValue}
          onChange={(value) => onChangeIncisionPropertiesValue(dataSettings, value, id)}
          isMainContainerColorSettings
          disabledOpacity
          disabledPadding
          disabledBackgroundColorBy
          disabledLineHeight
          disabledUnderline
          disabledFontColorByBlock
          disabledLetterSpacing
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<TreeIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, customRequest, settings: { nameFromDatabase } }) => (
        <SettingsFieldEntry
          setupIsOpen={setupIsOpen(id)}
          onSetupClick={onSetupClick(id)}
          onClick={onMetricClick(id)}
          isSelected={metricIsSelected(id)}
          fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
          canChangeField={!nameFromDatabase}
          onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
          disableColorPicker
          disableChangePriory
          key={id}
        >
          <IndicatorMetricField
            onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
            customRequest={customRequest}
            options={tableFields}
            dataSettings={dataSettings}
            id={id}
            isRealData={isRealData}
            fieldName={fieldName}
            operationType={operationType}
            modelMetaData={modelMetaData}
          />
        </SettingsFieldEntry>
      ))}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<TreeIndicatorInterface> = ({ metric: indicator }) => {
    const {
      id,
      settings: { nameFromDatabase, isShowValue, formatting },
    } = indicator;

    return (
      <>
        <ShowValueSettings
          onChange={(isShowValue: boolean) => onChangeShowValue(dataSettings, isShowValue, id)}
          value={isShowValue}
        />
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIndicatorNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <ModelSelectorSettings value={dataSettings.modelId} onChange={onModelIdChange} />
      <MetricSettings
        titleText="Разрезы"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={dataSettings.incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'tree')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
      />
      <MetricSettings
        titleText="Показатели"
        addButtonText="Добавить показатель"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={dataSettings.indicators}
        onAdd={() => onAddNewIndicator(dataSettings, 'tree')}
        onDelete={(id) => id && onDeleteIndicator(dataSettings, id)}
        disableAddingMetric={dataSettings.indicators.length === 1}
        controls={controls}
      />
      <SqlSettings
        //@ts-ignore
        astData={astOfVisualisation}
        sqlData={codeEditorData}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(dataSettings.incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(dataSettings.indicators)}
        onSave={(sqlSettingsChanges) =>
          onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'tree', {
            incision: { minValue: 1 },
            indicator: { minValue: 1, maxValue: 1 },
          })
        }
        modelMetaData={modelMetaData}
      />
      <LimitSettings value={dataSettings.limit} onChange={onLimitChange} />
    </>
  );
};
