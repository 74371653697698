import React from 'react';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { SqlField, SqlSection } from '../SqlSettings';
import { addQuotesToSqlIdentifiers } from 'utils/helpers/sqlHelpers';
import { useDisplayValues } from 'utils/hooks/useDisplayValues';
import { SqlFieldName, SqlRequestValues } from 'shared/types';

import { FilterSqlModalProps } from './types';

const formatSqlValues = (values: SqlRequestValues) => ({
  incisionRequest: addQuotesToSqlIdentifiers(values.incisionRequest || ''),
  filterAndGroupRequest: addQuotesToSqlIdentifiers(values.filterAndGroupRequest || ''),
});

export const FilterSqlModal = ({
  isOpen,
  onCloseEditor,
  localValues,
  setLocalValues,
  currentFullSize,
  onResizeChange,
  onFormatClick,
  modelMetaData,
  onSaveEditor,
  hasChanges,
}: FilterSqlModalProps) => {
  const formattedValues = formatSqlValues(localValues);
  const { displayValues, updateDisplayValue } = useDisplayValues(formattedValues);

  const handleChange = (field: SqlFieldName) => (value: string) => {
    setLocalValues((prev: SqlRequestValues) => ({ ...prev, [field]: value }));
    updateDisplayValue(field, value);
  };

  const handleFocus =
    (onEditorFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void) =>
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      if (onEditorFocus) {
        onEditorFocus(event);
      }
    };

  return (
    <ModalUniversal
      open={isOpen}
      onClose={onCloseEditor}
      headerText="Редактор SQL-запроса"
      width="95vw"
      maxHeight="95vh"
      disableEscapeKeyDown
    >
      <SqlSection
        sqlEditorSlot={({ onEditorFocus }) => (
          <>
            <SqlField
              title="Разрезы"
              handleChange={handleChange('incisionRequest')}
              value={displayValues.incisionRequest}
              onFocus={handleFocus(onEditorFocus)}
              currentFullSize={currentFullSize}
              onResizeChange={onResizeChange}
            />
            <SqlField
              title="Группировки и фильтры"
              handleChange={handleChange('filterAndGroupRequest')}
              value={displayValues.filterAndGroupRequest}
              onFocus={handleFocus(onEditorFocus)}
              currentFullSize={currentFullSize}
              onResizeChange={onResizeChange}
            />
          </>
        )}
        onFormatClick={onFormatClick}
        modelMetaData={modelMetaData}
        onSaveEditor={onSaveEditor}
        hasChanges={hasChanges}
        onCloseEditor={onCloseEditor}
      />
    </ModalUniversal>
  );
};
