import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ModelsStateInterface, ModelTabInterface } from 'store/reducers/models/types';

export const initialModelsStoreState: ModelsStateInterface = {
  tabs: {},
  tabsLoading: false,
  tables: {},
  tablesLoading: false,
  tablePreviews: {},
  tablePreviewLoading: new Set(),
  activeTabId: null,
  activeModelItemAlias: null,
  modelsFrom: {},
  modelsFromLoading: false,
  allModelRules: {
    allModelRulesList: [],
    loading: false,
  },
  modelRuleDataUsersAndGroups: {
    modelRule: {
      modelRulesData: null,
      loading: false,
    },
    allVariables: [],
    modelRuleUsers: [],
    modelRuleGroups: [],
    users: [],
    groups: [],
    activeModelRuleGroup: null,
    activeModelRuleUser: null,
  },
};

export const defaultTabName = 'Модель ';

export const getNewTab: (params: { id: string; name: string }) => ModelTabInterface = ({ id, name }) => ({
  id,
  name,
  zoom: 0.5,
  models: [],
  isLocalModels: true,
});

export const onPendingModelRule = (state: Draft<ModelsStateInterface>) => {
  const modelRuleDataUsersAndGroups = state.modelRuleDataUsersAndGroups;

  modelRuleDataUsersAndGroups.modelRule = { ...modelRuleDataUsersAndGroups.modelRule, loading: true };
  modelRuleDataUsersAndGroups.modelRule.modelRulesData = null;
  modelRuleDataUsersAndGroups.modelRuleUsers = [];
  modelRuleDataUsersAndGroups.modelRuleGroups = [];
  modelRuleDataUsersAndGroups.allVariables = [];
};

export const onModelRuleFulfilled = (
  state: Draft<ModelsStateInterface>,
  action: PayloadAction<FastBoard.API.ModelRuleWithUsersAndGroupsNamesDTO>,
) => {
  const modelRuleDataUsersAndGroups = state.modelRuleDataUsersAndGroups;

  const payload = action.payload;
  modelRuleDataUsersAndGroups.modelRule = { ...modelRuleDataUsersAndGroups.modelRule, loading: false };
  modelRuleDataUsersAndGroups.modelRule.modelRulesData = { ...payload };
  modelRuleDataUsersAndGroups.modelRuleUsers = payload.users;
  modelRuleDataUsersAndGroups.modelRuleGroups = payload.groups;
  modelRuleDataUsersAndGroups.allVariables = payload.variableNames;
};

export const onModelRuleRejected = (state: Draft<ModelsStateInterface>) => {
  const modelRuleDataUsersAndGroups = state.modelRuleDataUsersAndGroups;

  modelRuleDataUsersAndGroups.modelRule = { ...modelRuleDataUsersAndGroups.modelRule, loading: false };
  modelRuleDataUsersAndGroups.modelRule.modelRulesData = null;
  modelRuleDataUsersAndGroups.modelRuleUsers = [];
  modelRuleDataUsersAndGroups.modelRuleGroups = [];
  modelRuleDataUsersAndGroups.allVariables = [];
};
