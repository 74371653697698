import { ClickAwayListener, Popover } from '@mui/material';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { AccessGroupIcon } from 'assets/icons/access';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { Button } from 'modules/ui';
import { AddIcon } from 'assets/icons/withContainer';
import { RuleItem } from 'components/contents/Models/EditableMenu/PopoverRule/RuleItem';
import {
  addModelRuleAction,
  changeActiveRuleGroupIdAction,
  changeActiveRuleUserIdAction,
  createModelRuleAction,
  deleteByIdModelRuleAction,
  deleteModelRuleAction,
  loadAllModelRulesAction,
} from 'store/reducers/models/actions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getAllModelRules } from 'store/reducers/models/getters';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import Snackbar from 'services/Snackbar';
import { hasName } from 'utils/utils';
import { CreateRuleModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/CreateRuleModal';
import { createRuleModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/CreateRuleModal/constants';
import { modalRolePickerClassName } from 'components/contents/Models/EditableMenu/PopoverRule/constants';
import { NoopType } from 'types/global';
import { CreateRuleModel } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/CreateRuleModal/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { RuleModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal';
import { ruleEditModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/constants';
import { widthSelectionModal } from 'components/console/elements/SourcesConnection/DataSelectionModal/constants';
import { useEditRuleName } from 'components/contents/Models/hooks/useEditRuleName';

export interface PopoverRuleProps {
  modelId: string;
  disabled?: boolean;
  onOpenIsRules: NoopType;
}

export const PopoverRule = ({ modelId, onOpenIsRules, disabled }: PopoverRuleProps) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<ProjectIdParam>();
  const { onEditRuleNameModal } = useEditRuleName(projectId);

  const { allModelRulesList, loading } = useSelector(getAllModelRules);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLDivElement | null>(null);

  const onRoleSelectorClick: MouseEventHandler<HTMLDivElement> = (e) => {
    setPopoverAnchorEl(e.currentTarget);
    onOpenIsRules();
  };
  const onCloseMenu = () => {
    setPopoverAnchorEl(null);
    onOpenIsRules();
  };

  useEffect(() => {
    dispatch(loadAllModelRulesAction({ modelId, projectId: projectId || '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, modelId]);

  const onDeleteRule = useCallback(
    async (ruleId: string) => {
      try {
        if (projectId) {
          const action = await dispatch(deleteModelRuleAction({ ruleId, projectId })).unwrap();
          if (action) {
            await dispatch(deleteByIdModelRuleAction(ruleId));
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  const onDeleteRuleModal = useCallback(
    async (ruleId: string, ruleName: string) => {
      try {
        await dispatch(
          openConfirmationModalAction({
            confirmationButtonText: 'Удалить',
            description: `Действительно удалить права «${ruleName}»?`,
            onConfirm: () => onDeleteRule(ruleId),
            titleText: 'Удаление прав',
            disableModalPortal: true,
          }),
        );
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteRule],
  );

  const onCloseCreateRuleModal = () => dispatch(closeModalAction(createRuleModal));

  const onCreateModelRule = useCallback(
    async ({ name, file }: CreateRuleModel) => {
      if (!name) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(allModelRulesList, name)) {
        return Snackbar.show('Правило с таким именем уже существует', 'error');
      }

      try {
        const resProtect = await dispatch(createModelRuleAction({ modelId, projectId: projectId || '', name, file })).unwrap();

        if (resProtect) {
          const { id, name, isActive } = resProtect;
          dispatch(addModelRuleAction({ id, name, isActive }));

          onCloseCreateRuleModal();
          await onEditRuleModal(resProtect.id);
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allModelRulesList, onCloseCreateRuleModal, modelId, projectId],
  );

  const onCreateRuleModal = useCallback(
    () => {
      dispatch(
        openModalTypedAction({
          Component: CreateRuleModal,
          componentProps: {
            onClose: onCloseCreateRuleModal,
            onSave: onCreateModelRule,
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            headerText: 'Создать правило',
            disableModalPortal: true,
          },
          name: createRuleModal,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseCreateRuleModal, onCreateModelRule],
  );

  const onCloseEditRuleModal = () => {
    dispatch(closeModalAction(ruleEditModal));
    dispatch(changeActiveRuleUserIdAction(null));
    dispatch(changeActiveRuleGroupIdAction(null));
  };

  const onEditRuleModal = useCallback(
    async (ruleId: string) => {
      dispatch(
        openModalTypedAction({
          Component: RuleModal,
          componentProps: {
            onClose: onCloseEditRuleModal,
            ruleId,
            projectId: projectId || '',
            modelId,
          },
          modalSettings: {
            position: 'static',
            padding: '0',
            width: widthSelectionModal,
            headerText: 'Изменение правила',
            disableModalPortal: true,
          },
          name: ruleEditModal,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditRuleModal, projectId, modelId],
  );

  return (
    <FlexContainer>
      <IconWrapper
        onClick={
          !disabled
            ? (e) => {
                dispatch(loadAllModelRulesAction({ modelId, projectId: projectId || '' }));
                onRoleSelectorClick(e);
              }
            : undefined
        }
        iconWidth="17px"
        iconHeight="17px"
        containerHeight="30px"
        containerWidth="30px"
        Icon={AccessGroupIcon}
        hoverColorVar={ColorVarsEnum.Level_2}
        colorVar={disabled ? ColorVarsEnum.Level_4 : ColorVarsEnum.Level_2}
      />
      {!!popoverAnchorEl && (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ backgroundColor: 'red' }}
          anchorEl={popoverAnchorEl}
          open={!!popoverAnchorEl}
          className={modalRolePickerClassName}
        >
          <ClickAwayListener onClickAway={onCloseMenu}>
            <FlexContainer
              width="300px"
              position="relative"
              flexDirection="column"
              padding="16px"
              backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
              boxShadow="0 4px 20px 0 rgba(0, 0, 0, 0.15)"
              gap="16px"
            >
              {!!popoverAnchorEl && (
                <>
                  <PrimaryTextParagraph
                    fontWeight="bold"
                    fontSize="16px"
                    lineHeight="16px"
                    color={`var(${ColorVarsEnum.Level_1})`}
                  >
                    Правила
                  </PrimaryTextParagraph>
                  <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
                  <FlexContainer flexDirection="column" gap="8px" maxHeight="300px" overflow="auto" padding="0 55px 0 0">
                    {allModelRulesList.length !== 0 &&
                      allModelRulesList.map(({ id, name, isActive }) => (
                        <RuleItem
                          key={id}
                          title={name}
                          isActive={isActive}
                          onEdit={() => onEditRuleNameModal(id, name)}
                          onDelete={() => onDeleteRuleModal(id, name)}
                          onClick={() => onEditRuleModal(id)}
                        />
                      ))}
                  </FlexContainer>

                  <Button
                    width="132px"
                    heightSize="small"
                    text="Создать правило"
                    leftIcon={<AddIcon />}
                    onClick={onCreateRuleModal}
                  />
                </>
              )}
            </FlexContainer>
          </ClickAwayListener>
        </Popover>
      )}
    </FlexContainer>
  );
};
