import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isEqual from 'lodash/isEqual';
import { defaultThemePaletteLength } from 'modules/settingsContainer/ColorPicker/constants';
import {
  ColorValue,
  ColorValuesByThemeType,
  ColorValueType,
  PaletteValue,
  PaletteValuesByThemeType,
  PaletteValueType,
  ThemeColorValue,
} from 'modules/settingsContainer/ColorPicker/types';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getActivePalette,
  getGroupNames,
  getPaletteColors,
  getPriorityIdsOfActivePalette,
} from 'store/reducers/palettes/getters';
import { getActiveTheme, getActiveThemeColors } from 'store/reducers/themes/getters';
import { MapRecordType } from 'types/global';
import { getValueByKeys } from 'utils/utils';

type ReturnedColorValues<Value> = Value extends PaletteValuesByThemeType
  ? string[] | null
  : Value extends ColorValuesByThemeType
  ? string | null
  : Value extends null
  ? null
  : Value extends (PaletteValuesByThemeType | null)[]
  ? (string[] | null)[]
  : Value extends (ColorValuesByThemeType | null)[]
  ? (string | null)[]
  : never;

export type GetColorsFnType = <
  Value extends
    | PaletteValuesByThemeType
    | ColorValuesByThemeType
    | null
    | (PaletteValuesByThemeType | null)[]
    | (ColorValuesByThemeType | null)[],
>(
  value: Value,
) => ReturnedColorValues<Value>;

export const useColorValues = () => {
  const activeTheme = useSelector(getActiveTheme);
  const paletteColors = useSelector(getPaletteColors);
  const groupNames = useSelector(getGroupNames);
  const activePalette = useSelector(getActivePalette);
  const priorityIdsOfPalette = useSelector(getPriorityIdsOfActivePalette);
  const activeThemeSchema = useSelector(getActiveThemeColors);
  const defaultColor = activeThemeSchema?.[ColorVarsEnum.Level_2];

  const defaultThemePalette = useMemo(
    () => (activeTheme?.elementColors || []).slice(0, defaultThemePaletteLength).map(({ hex }) => hex),
    [activeTheme?.elementColors],
  );

  const getValueAsPalette = useCallback(
    (paletteValue: PaletteValueType) => {
      if (isEqual({ type: 'palette' }, paletteValue)) {
        return defaultThemePalette;
      }

      const group = paletteColors?.[(paletteValue as PaletteValue).groupId];

      return group ? Object.values(group) : null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paletteColors, JSON.stringify(defaultThemePalette)],
  );

  const getValueAsColor = useCallback(
    (paletteValue: ColorValueType) => {
      if ((paletteValue as ThemeColorValue)?.level) {
        return defaultThemePalette[(paletteValue as ThemeColorValue)?.level - 1];
      }

      const { colorId, groupId } = paletteValue as ColorValue;

      return paletteColors?.[groupId]?.[colorId] || null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paletteColors, JSON.stringify(defaultThemePalette)],
  );

  const getHighPriorityColorValue = useCallback<
    (colorPickerValue: PaletteValuesByThemeType | ColorValuesByThemeType | null) => PaletteValueType | ColorValueType | undefined
  >(
    (colorPickerValue) =>
      colorPickerValue
        ? getValueByKeys(colorPickerValue as MapRecordType<PaletteValueType | ColorValueType>, priorityIdsOfPalette)
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(priorityIdsOfPalette), priorityIdsOfPalette],
  );

  const getColor = useCallback<
    (colorPickerValue: PaletteValuesByThemeType | ColorValuesByThemeType | null) => Array<string> | string | null
  >(
    (colorPickerValue) => {
      if (!colorPickerValue || priorityIdsOfPalette.length == 0 || !paletteColors) return null;

      const paletteValue = getHighPriorityColorValue(colorPickerValue);

      if (!paletteValue) return null;

      const { type } = paletteValue;

      if (type === 'palette') {
        return getValueAsPalette(paletteValue as PaletteValueType);
      }

      if (type === 'color') {
        return getValueAsColor(paletteValue as ColorValueType);
      }

      return null;
    },
    [
      priorityIdsOfPalette.length,
      getHighPriorityColorValue,
      paletteColors,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(defaultThemePalette),
      getValueAsColor,
      getValueAsPalette,
    ],
  );

  const getColorValues = useCallback(
    (
      value:
        | Array<PaletteValuesByThemeType>
        | Array<ColorValuesByThemeType>
        | null
        | PaletteValuesByThemeType
        | ColorValuesByThemeType,
    ) => {
      if (!value) return null;

      if (Array.isArray(value)) {
        return value.map((color) => getColor(color));
      }

      return getColor(value);
    },
    [getColor],
  );

  return {
    activeTheme,
    activePaletteColor: paletteColors,
    paletteId: activePalette?.id,
    showGroupNames: activePalette?.showGroupNames || false,
    activePalette,
    priorityIdsOfPalette,
    getHighPriorityColorValue,
    defaultThemePalette,
    getColorValues: getColorValues as GetColorsFnType,
    activeThemeSchema,
    groupNames,
    defaultColor,
  };
};

export const usePopover = () => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLDivElement | null>(null);

  return {
    popoverAnchorEl,
    setPopoverAnchorEl,
  };
};

export const useColorPickerValue = <Value>() => {
  const [activeColorPickerValue, setActiveColorPickerValue] = useState<Value | null>(null);

  return {
    activeColorPickerValue,
    setActiveColorPickerValue,
  };
};
