import { useState, useCallback } from 'react';
import { SqlFieldName, SqlRequestValues } from 'shared/types';

export const useDisplayValues = (initialValues: SqlRequestValues) => {
  const [displayValues, setDisplayValues] = useState<SqlRequestValues>(initialValues);

  const updateDisplayValue = useCallback((field: SqlFieldName, value: string) => {
    setDisplayValues((prev: SqlRequestValues) => ({ ...prev, [field]: value }));
  }, []);

  return { displayValues, updateDisplayValue };
};
