import React, { useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, Checkbox, Select } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { useAppDispatch } from 'store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { loadExportingUsersAndGroupsAction } from 'store/reducers/models/actions';
import { Form } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/AddUserModal/styles';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { ExportingUsersAndGroupsCallback } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/ExportingUsersAndGroupsModal/types';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { formatRuleItems } from 'shared/constants/models';

interface ExportingUsersAndGroupsModalProps {
  onSave: () => Promise<void>;
  projectId: string;
  ruleId: string;
  ruleName: string;
}

export const ExportingUsersAndGroupsModal: ModalComponentType<ExportingUsersAndGroupsModalProps> = ({
  onClose,
  projectId,
  onSave,
  ruleId,
  ruleName,
}) => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ExportingUsersAndGroupsCallback>({
    mode: 'onSubmit',
    defaultValues: {
      fileFormatRuleType: 'csv',
    },
  });

  const onSubmit: SubmitHandler<ExportingUsersAndGroupsCallback> = useCallback(
    async ({ saveCurrentChanges, fileFormatRuleType }) => {
      try {
        if (isSubmitting) {
          return;
        }

        if (saveCurrentChanges) {
          await onSave();
        }

        await dispatch(loadExportingUsersAndGroupsAction({ ruleId, ruleName, typeFile: fileFormatRuleType, projectId }));
        onClose();
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, onSave, ruleName, projectId, ruleId],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <Controller
        render={({ field }) => (
          <Select title="Формат файла" options={formatRuleItems} width="100%" placeholder="СУБД" {...field} value={field.value} />
        )}
        name="fileFormatRuleType"
        control={control}
      />

      <PrimaryTextSpan
        color={`var(${ColorVarsEnum.Level_1})`}
        fontSize="14px"
        fontWeight="bold"
        textAlign="center"
        marginBottom="8px"
        lineHeight="18px"
      >
        Правило содержит несохраненные изменения. Сохранить их?
      </PrimaryTextSpan>

      <Controller
        name="saveCurrentChanges"
        control={control}
        render={({ field }) => (
          <Checkbox
            padding="0"
            name="saveCurrentChanges"
            checked={field.value}
            label="Да, сохранить текущие изменения"
            id="saveCurrentChanges"
            onChange={field.onChange}
          />
        )}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Экспорт" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
