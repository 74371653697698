import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { DateIcon } from 'assets/icons/editor';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { useMemo } from 'react';
import { CodeEditorDataInterface } from 'store/reducers/visualisations/types';
import { ErrorMessageInterface, FC, NoopType, NoopValueType, ReactChildrenType } from 'types/global';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { useModalState } from 'utils/hooks/modalState';
import { useLocalValues } from 'utils/hooks/localValues';
import {
  codeEditorTitle,
  generateMetricAstMapData,
  getIncisionsChanges,
  getIndicatorsChanges,
  getServiceChanges,
} from 'modules/settingsContainer/common/data/SqlSettings/cosntants';
import { SqlAutocomplete, SqlAutocompleteProps } from 'modules/ui/SqlAutocomplete';
import { VisualisationAstInterface } from 'store/reducers/ast/types';
import { SqlSettingsChangesInterface } from 'modules/settingsContainer/common/data/SqlSettings/types';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { patterns } from 'constants/global';
import { LocalValuesInterface, StyledInfoIcon } from 'modules/settingsContainer/common/data/SqlSettings/styles';
import { Button } from 'modules/ui';
import Snackbar from 'services/Snackbar';
import { AdviceEditor } from 'modules/ui/SqlAutocomplete/types';
import { ListWithSearch } from 'modules/settingsContainer/SearchList/ListWithSearch';
import { usePasteToSqlEditor } from 'modules/visualisations/hooks/pasteToSqlEditor';
import { ModelFromMetaType } from 'store/reducers/models/types';
import { ListItem } from 'modules/ui/lists/MapList/item/ListItem';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { Maximize, Minimize } from 'assets/icons/withContainer';
import { formatSql } from 'utils/SQL/formatSQL';
import { useFullSize } from 'utils/hooks/fullSize';
import { WrapperContainer } from 'components/shared/ui/menu/MenuIconList/styles';
import { addQuotesToSqlIdentifiers } from 'utils/helpers/sqlHelpers';

interface TitledFieldInterface {
  title: string;
  afterTitleSlot?: ReactChildrenType;
  resizeSlot?: ReactChildrenType;
  isCurrentFieldFullSize?: boolean;
}

const TitledField: FC<TitledFieldInterface> = ({ children, title, afterTitleSlot, resizeSlot, isCurrentFieldFullSize }) => {
  const { onMouseEnter, onMouseLeave, isResizeVisible } = useFullSize();

  return (
    <FlexContainer
      style={{
        maxHeight: isCurrentFieldFullSize ? '80px' : '100%',
        transition: 'max-height 0.5s ease-in-out',
        willChange: 'max-height',
        gap: '8px',
        flex: '1 1 0',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        minHeight="24px"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <FlexContainer alignItems="center" gap="8px">
          <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
            {title}
          </PrimaryTextSpan>
          {afterTitleSlot}
        </FlexContainer>
        {isResizeVisible && resizeSlot}
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};

interface SqlFieldProps
  extends Pick<SqlAutocompleteProps, 'handleChange' | 'value' | 'adviceEditor' | 'onFocus'>,
    Pick<TitledFieldInterface, 'title'> {
  currentFullSize?: string | null;
  onResizeChange: (value: string) => () => void;
}

export const SqlField = ({ title, currentFullSize, onResizeChange, ...sqlAutocompleteProps }: SqlFieldProps) => {
  const isCurrentFieldFullScreen = currentFullSize && currentFullSize !== title;

  return (
    <TitledField
      title={title}
      isCurrentFieldFullSize={!!isCurrentFieldFullScreen}
      afterTitleSlot={
        <StyledTooltip
          title={
            <FlexContainer flexDirection="column">
              {Object.entries(patterns).map(([from, to]) => (
                <FlexContainer key={`${from}_${to}`}>{`'${from}' - '${to}'`}</FlexContainer>
              ))}
            </FlexContainer>
          }
        >
          <StyledInfoIcon />
        </StyledTooltip>
      }
      resizeSlot={
        <WrapperContainer>
          <IconWrapper
            onClick={onResizeChange(title)}
            Icon={currentFullSize === title ? Minimize : Maximize}
            disableDefaultStyles
          />
        </WrapperContainer>
      }
    >
      <SqlAutocomplete height="100%" autofocus {...sqlAutocompleteProps} />
    </TitledField>
  );
};

interface AdviceListProps {
  title: string;
  list?: AdviceEditor[];
}

export const AdviceList = ({ title, list }: AdviceListProps) => {
  return list && list.length ? (
    <FlexContainer flexDirection="column" gap="8px">
      <PrimaryTextSpan fontSize="12px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_1})`} fontWeight={700}>
        {`${title}:`}
      </PrimaryTextSpan>
      <FlexContainer flexDirection="column" gap="10px" padding="0 8px 0">
        {list?.map((el) => (
          <PrimaryTextSpan
            key={el.caption}
            fontSize="12px"
            lineHeight="14px"
            color={`var(${ColorVarsEnum.Level_1})`}
            fontWeight={400}
          >
            {el.caption}
          </PrimaryTextSpan>
        ))}
      </FlexContainer>
    </FlexContainer>
  ) : null;
};

export interface SqlSectionProps {
  modelMetaData?: ModelFromMetaType[];
  adviceSlot?: ReactChildrenType;
  sqlEditorSlot: (props: { onEditorFocus: SqlAutocompleteProps['onFocus'] }) => JSX.Element;
  onSaveEditor: NoopType;
  onCloseEditor: NoopType;
  hasChanges: boolean;
  onFormatClick: NoopType;
}

export const SqlSection = ({
  sqlEditorSlot,
  adviceSlot,
  modelMetaData,
  onSaveEditor,
  hasChanges,
  onCloseEditor,
  onFormatClick,
}: SqlSectionProps) => {
  const { setSelectedColumns, selectedColumns, onEditorFocus, onAddToEditor } = usePasteToSqlEditor();

  const modelMetaDataList = modelMetaData?.map(({ columns, alias }) => ({
    items: columns?.map(({ name, type }) => ({ title: name, type })),
    title: alias,
    type: alias,
  }));

  return (
    <FlexContainer width="100%" flex="1 1 0" flexDirection="column" padding="16px" gap="8px">
      <FlexContainer width="100%" flex="1 1 0" gap="32px">
        {modelMetaData && !!modelMetaData.length && (
          <FlexContainer width="300px" flexDirection="column">
            <FlexContainer flexDirection="column" flex="1 1 0">
              <TitledField title="Карта модели">
                <FlexContainer width="100%" flex="1 1 0" flexDirection="column">
                  <ListWithSearch
                    modelMetaData={modelMetaDataList || []}
                    onSelectItem={setSelectedColumns}
                    selectedOptions={selectedColumns}
                    renderItem={({ item, key, isSelected, onSelectChange }) => (
                      <ListItem
                        key={key}
                        id={item.id}
                        title={item.title}
                        Icon={item.Icon}
                        disabled={item.disabled}
                        onChecked={onSelectChange}
                        isChecked={isSelected}
                      />
                    )}
                  />
                  <FlexContainer alignItems="center" justifyContent="center" padding="20px 0 0 0">
                    <Button
                      disabled={!selectedColumns.length}
                      text="Добавить в текст"
                      heightSize="normal"
                      onClick={onAddToEditor}
                    />
                  </FlexContainer>
                </FlexContainer>
              </TitledField>
            </FlexContainer>
          </FlexContainer>
        )}
        <FlexContainer flex="1 1 0" gap="16px">
          <FlexContainer flexDirection="column" width="100%" flex="1 1 0" gap="12px">
            {sqlEditorSlot({ onEditorFocus })}
            <FlexContainer justifyContent="space-between">
              <Button text="Форматировать" heightSize="normal" width="auto" onClick={onFormatClick} needBackground={false} />
              <FlexContainer justifyContent="flex-end">
                <FlexContainer marginRight="10px">
                  <Button text="Отменить" heightSize="normal" width="95px" onClick={onCloseEditor} needBackground={false} />
                </FlexContainer>
                <Button disabled={!hasChanges} text="Сохранить" heightSize="normal" width="95px" onClick={onSaveEditor} />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        {adviceSlot && (
          <FlexContainer flexDirection="column" width="230px" gap="24px">
            {adviceSlot}
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

interface SqlSettingsProps {
  modelMetaData: ModelFromMetaType[];
  sqlData: CodeEditorDataInterface;
  astData: VisualisationAstInterface;
  adviceEditorIncision?: AdviceEditor[];
  adviceEditorIndicator?: AdviceEditor[];
  adviceEditorService?: AdviceEditor[];
  serviceSqlString?: string;
  serviceTitle?: string;
  onSave: NoopValueType<SqlSettingsChangesInterface>;
}

export const SqlSettings = ({
  sqlData: { incisionSqlString, indicatorSqlString, filterAndGroupSqlString },
  astData,
  adviceEditorIncision,
  adviceEditorIndicator,
  adviceEditorService,
  serviceTitle,
  serviceSqlString,
  onSave,
  modelMetaData,
}: SqlSettingsProps) => {
  const { isOpen, onOpen, onClose } = useModalState();
  const { currentFullSize, onResizeChange } = useFullSize();

  const defaultRequests = useMemo(
    () => ({
      incisionRequest: addQuotesToSqlIdentifiers(incisionSqlString || ''),
      indicatorRequest: addQuotesToSqlIdentifiers(indicatorSqlString || ''),
      filterAndGroupRequest: addQuotesToSqlIdentifiers(filterAndGroupSqlString || ''),
      serviceRequest: addQuotesToSqlIdentifiers(serviceSqlString || ''),
    }),
    [incisionSqlString, indicatorSqlString, filterAndGroupSqlString, serviceSqlString],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incisionsAstMapData = useMemo(() => generateMetricAstMapData(astData.incisions), [JSON.stringify(astData.incisions)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indicatorsAstMapData = useMemo(() => generateMetricAstMapData(astData.indicators), [JSON.stringify(astData.indicators)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const servicesAstMapData = useMemo(() => generateMetricAstMapData(astData.variables), [JSON.stringify(astData.variables)]);

  const onChange = ({ incisionRequest, indicatorRequest, serviceRequest, filterAndGroupRequest }: typeof defaultRequests) => {
    try {
      const incisionsChanges = getIncisionsChanges({ incisionsAstMapData, incisionRequest });
      const indicatorsChanges = getIndicatorsChanges({ indicatorsAstMapData, indicatorRequest });
      const servicesChanges = getServiceChanges({ servicesAstMapData, serviceRequest });

      onSave({ incisionsChanges, indicatorsChanges, servicesChanges, filterAndGroupRequest: filterAndGroupRequest || '' });
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
      throw Error(message);
    }
  };

  const {
    localValues,
    setLocalValues,
    onSave: onLocalSave,
    onCancel,
    hasChanges,
  } = useLocalValues({
    value: defaultRequests,
    onChange,
  });

  const formatSqlValues = (): LocalValuesInterface => {
    const keysToFormat: Array<keyof LocalValuesInterface> = [
      'incisionRequest',
      'indicatorRequest',
      'serviceRequest',
      'filterAndGroupRequest',
    ];
    const formattedValues: LocalValuesInterface = {};

    Object.entries(localValues).forEach(([key, value]) => {
      if (keysToFormat.includes(key as keyof LocalValuesInterface) && value) {
        formattedValues[key as keyof LocalValuesInterface] = formatSql(value);
      }
    });

    return formattedValues;
  };

  const onFormatClick = () => {
    const { incisionRequest, indicatorRequest, serviceRequest, filterAndGroupRequest } = formatSqlValues();

    setLocalValues({
      incisionRequest: incisionRequest ?? '',
      indicatorRequest: indicatorRequest ?? '',
      serviceRequest: serviceRequest ?? '',
      filterAndGroupRequest: filterAndGroupRequest ?? '',
    });
  };

  const onCloseEditor = () => {
    onClose();
    onCancel();
  };

  const onSaveEditor = () => {
    const quotedValues = {
      incisionRequest: addQuotesToSqlIdentifiers(localValues.incisionRequest || ''),
      indicatorRequest: addQuotesToSqlIdentifiers(localValues.indicatorRequest || ''),
      serviceRequest: addQuotesToSqlIdentifiers(localValues.serviceRequest || ''),
      filterAndGroupRequest: addQuotesToSqlIdentifiers(localValues.filterAndGroupRequest || ''),
    };
    setLocalValues(quotedValues);
    onFormatClick();
    onLocalSave();
    onClose();
  };

  return (
    <>
      <MainContainerSettings titleText="SQL" ButtonIcon={DateIcon} onClickButtonIcon={onOpen} />
      <ModalUniversal
        open={isOpen}
        onClose={onCloseEditor}
        headerText={codeEditorTitle}
        width="95vw"
        maxHeight="95vh"
        disableEscapeKeyDown
      >
        <SqlSection
          sqlEditorSlot={({ onEditorFocus }) => (
            <>
              {!serviceTitle && (
                <SqlField
                  title="Разрезы"
                  handleChange={(incisionRequest) => setLocalValues({ ...localValues, incisionRequest })}
                  value={localValues.incisionRequest || ''}
                  adviceEditor={adviceEditorIncision}
                  onFocus={onEditorFocus}
                  currentFullSize={currentFullSize}
                  onResizeChange={onResizeChange}
                />
              )}
              {!serviceTitle && (
                <SqlField
                  title="Показатели"
                  handleChange={(indicatorRequest) => setLocalValues({ ...localValues, indicatorRequest })}
                  value={localValues.indicatorRequest || ''}
                  adviceEditor={adviceEditorIndicator}
                  onFocus={onEditorFocus}
                  currentFullSize={currentFullSize}
                  onResizeChange={onResizeChange}
                />
              )}
              {serviceTitle && (
                <SqlField
                  title={serviceTitle || ''}
                  handleChange={(serviceRequest) => setLocalValues({ ...localValues, serviceRequest })}
                  value={localValues.serviceRequest || ''}
                  adviceEditor={adviceEditorService}
                  onFocus={onEditorFocus}
                  currentFullSize={currentFullSize}
                  onResizeChange={onResizeChange}
                />
              )}
              <SqlField
                title="Группировки и фильтры"
                handleChange={(filterAndGroupRequest) => setLocalValues({ ...localValues, filterAndGroupRequest })}
                value={localValues.filterAndGroupRequest || ''}
                adviceEditor={adviceEditorIndicator}
                onFocus={onEditorFocus}
                currentFullSize={currentFullSize}
                onResizeChange={onResizeChange}
              />
            </>
          )}
          adviceSlot={
            <>
              <AdviceList title="Разрезы" list={adviceEditorIncision} />
              <AdviceList title="Показатели" list={adviceEditorIndicator} />
              <AdviceList title={serviceTitle || ''} list={adviceEditorService} />
            </>
          }
          onFormatClick={onFormatClick}
          modelMetaData={modelMetaData}
          hasChanges={hasChanges}
          onSaveEditor={onSaveEditor}
          onCloseEditor={onCloseEditor}
        />
      </ModalUniversal>
    </>
  );
};
