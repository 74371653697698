export const SQL_KEYWORDS = new Set([
  'select',
  'from',
  'where',
  'group',
  'by',
  'having',
  'order',
  'limit',
  'as',
  'and',
  'or',
  'not',
  'in',
  'like',
  'ilike',
  'is',
  'null',
  'true',
  'false',
  'case',
  'when',
  'then',
  'else',
  'end',
  'distinct',
  'union',
  'all',
  'intersect',
  'except',
  'offset',
  'fetch',
  'first',
  'next',
  'rows',
  'only',
  'with',
  'replace',
  'sum',
  'count',
  'desc',
  'asc',
]);
