import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getProjectSettingsStore = createSelector(getState, (state) => state.projectSettings);

export const getProjectSettings = createSelector(getProjectSettingsStore, (state) => state.projectSettings);
export const getServerStateOfProjectSettings = createSelector(
  getProjectSettingsStore,
  (state) => state.serverStateOfProjectSettings,
);
export const getHasChangesOfProjectSettings = createSelector(
  [getServerStateOfProjectSettings, getProjectSettings],
  (serverState, currentState) => {
    // Тут удаляем Rls так как нам не нужно сохранять изменения, оно лишь для предпросмотра
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { rls, ...restCurrentState } = currentState;

    return serverState === null ? false : !isEqual(serverState, restCurrentState);
  },
);

export const getIsViewMode = createSelector(getProjectSettings, (projectSettings) => projectSettings.isViewMode);
export const getRLS = createSelector(getProjectSettings, (projectSettings) => projectSettings.rls);
export const getIsShowWorkSpace = createSelector(getProjectSettings, (projectSettings) => projectSettings.isShowWorkSpace);
export const getScaleOfPage = createSelector(getProjectSettings, (projectSettings) => projectSettings.scaleOfPage);

export const getProjectSettingsLoading = createSelector(getProjectSettingsStore, (state) => state.projectSettingsLoading);
export const getDashboardComponentsLoading = createSelector(getProjectSettingsStore, (state) => state.dashboardComponentsLoading);
export const getDashboardComponentsLoaded = createSelector(getProjectSettingsStore, (state) => state.dashboardComponentsLoaded);
export const getDashboardElementsLoading = createSelector(getProjectSettingsStore, (state) => state.dashboardElementsLoading);
export const getDashboardComponentsRendering = createSelector(
  getProjectSettingsStore,
  (state) => state.dashboardComponentsRendering,
);
export const getThemeSettingsLoadingLoading = createSelector(getProjectSettingsStore, (state) => state.themeSettingsLoading);

export const getDefaultModelId = createSelector(getProjectSettingsStore, (state) => state.defaultModelId);
export const getServerStateOfDefaultModelId = createSelector(
  getProjectSettingsStore,
  (state) => state.serverStateOfDefaultModelId,
);
export const getHasChangesOfDefaultModelId = createSelector(
  [getServerStateOfDefaultModelId, getDefaultModelId],
  (serverState, currentState) => (serverState === null ? false : serverState !== currentState),
);

export const getDefaultModelLoading = createSelector(getProjectSettingsStore, (state) => state.defaultModelLoading);

export const getSaveInProgress = createSelector(getProjectSettingsStore, (state) => state.saveInProgress);
export const getLoadInProgress = createSelector(getProjectSettingsStore, (state) => state.loadInProgress);

export const getLastSettingTimestamp = createSelector(getProjectSettingsStore, (state) => state.lastSettingTimestamp);

export const getProjectTask = createSelector(getProjectSettingsStore, (state) => state.projectTask);
