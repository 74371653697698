import { SQL_KEYWORDS } from './constants';

export const addQuotesToSqlIdentifiers = (sql: string): string => {
  return sql.replace(/"(\w+)"\."(\w+)"|"([^"]+)"|(\w+(?:\.\w+)*)/g, (match, part1, part2, quotedIdentifier, fullIdentifier) => {
    if (part1 && part2) {
      return `"${part1}.${part2}"`;
    } else if (quotedIdentifier) {
      return `"${quotedIdentifier}"`;
    } else if (fullIdentifier) {
      // Handle identifiers not already in quotes
      if (SQL_KEYWORDS.has(fullIdentifier.toLowerCase()) || /^\d+$/.test(fullIdentifier)) {
        return fullIdentifier;
      }
      return `"${fullIdentifier}"`;
    }
    return match;
  });
};

export const formatSqlDataArray = <T extends { [key: string]: any }>(data: T[], sqlKeys: (keyof T)[]): T[] => {
  return data.map((item) => {
    const formattedItem = { ...item };
    sqlKeys.forEach((key) => {
      if (typeof formattedItem[key] === 'string') {
        formattedItem[key] = addQuotesToSqlIdentifiers(formattedItem[key] as string) as T[keyof T];
      }
    });
    return formattedItem;
  });
};
