import { useCallback } from 'react';
import Snackbar from 'services/Snackbar';
import { useAppDispatch } from 'store';
import { updateModelRuleAction, updateModelRuleByIdAction, updateModelRuleNameAction } from 'store/reducers/models/actions';
import { hasName } from 'utils/utils';
import { useSelector } from 'react-redux';
import { getAllModelRules, getModelRule } from 'store/reducers/models/getters';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';

export const useEditRuleName = (projectId?: string) => {
  const dispatch = useAppDispatch();
  const { allModelRulesList } = useSelector(getAllModelRules);
  const { modelRulesData } = useSelector(getModelRule);

  const onEditRuleName = useCallback(
    async (id: string, name: string) => {
      try {
        if (!name) {
          return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
        }

        if (hasName(allModelRulesList, name)) {
          return Snackbar.show('Права с таким именем уже существует', 'error');
        }
        if (projectId) {
          const actionRes = await dispatch(updateModelRuleNameAction({ id, name, projectId: projectId })).unwrap();
          if (actionRes) {
            const { id, name, isActive } = actionRes;
            dispatch(updateModelRuleByIdAction({ modelRule: { isActive, id, name } }));

            if (modelRulesData) {
              dispatch(updateModelRuleAction({ ...modelRulesData, name }));
            }
          }
        }
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allModelRulesList, projectId],
  );

  const onEditRuleNameModal = useCallback(
    (id: string, name: string) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Готово',
          onConfirm: (name) => onEditRuleName(id, name?.trim() || ''),
          valueInput: name,
          isRenamable: true,
          titleText: 'Переименовать права',
          width: '320px',
          disableModalPortal: true,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onEditRuleName],
  );

  return {
    onEditRuleNameModal,
  };
};
