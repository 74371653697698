import { FlexContainer } from 'styles/FlexContainer';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFilterQuery } from 'modules/filters/hooks/filter';
import { DateFilterInterface, DateFilterSelectedValuesInterface } from 'store/reducers/filters/types';
import { DatePicker } from 'modules/ui/inputs/DatePicker';
import { defaultDateFormat } from 'constants/dates';
import { getDateByFormat, getStringDateByFormat } from 'utils/dates';
import { OnChangeType, PeriodDateType } from 'modules/ui/inputs/DatePicker/types';
import { getFilterFieldName } from 'store/reducers/filters/constants';
import { ActiveObjectFilters } from 'store/reducers/visualisations/types';
import { ByType } from 'types/store';
import Button from 'modules/ui/Button';

interface DateFilterProps {
  data: DateFilterInterface;
  elementId: string;
  whereQuery: string;
  selectedValues: { startDate: string | null; endDate: string | null; byType: ByType };
  onUpdateFilter: (elementId: string, payload: Partial<ActiveObjectFilters>) => void;
}

export const DateFilter = ({ data, elementId, selectedValues, whereQuery, onUpdateFilter }: DateFilterProps) => {
  const [oldestAndNewestDates, setOldestAndNewestDates] = useState<string[]>([]);
  const [activeValue, setActiveValue] = useState<DateFilterSelectedValuesInterface['selectedValues'] | null>(
    selectedValues || null,
  );

  const { isRealData, id, nameSettings, modelId, sqlData } = data;

  const { filterValues } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    whereQuery,
    isDataFilter: true,
    isFetchRequiredWhenDataExists: true,
    sqlData,
  });

  const value = useMemo<Date | PeriodDateType>(
    () =>
      activeValue?.byType === 'byToday'
        ? new Date()
        : [
            getDateByFormat(activeValue?.startDate || null, defaultDateFormat),
            getDateByFormat(activeValue?.endDate || null, defaultDateFormat),
          ],
    [activeValue],
  );

  const onChange: OnChangeType<boolean | undefined> = useCallback(
    ({ dates, byType }) => {
      let startDate: null | string = null,
        endDate: null | string = null;

      if (Array.isArray(dates)) {
        const [start, end] = dates;
        startDate = getStringDateByFormat(start, defaultDateFormat);
        endDate = getStringDateByFormat(end, defaultDateFormat);
      }

      const newValue = { startDate, endDate, byType };

      if (byType === 'byToday' || (startDate && endDate)) {
        setActiveValue(newValue);
      }

      onUpdateFilter(elementId, { selectedValues: newValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActiveValue, isRealData, activeValue],
  );

  const onResetFilterValues = () => {
    setActiveValue(null);
    onUpdateFilter(elementId, { selectedValues: [] });
  };

  useEffect(() => {
    if (isRealData && filterValues && filterValues.length > 0) {
      const minDate = filterValues[0].value,
        maxDate = filterValues[1].value;

      if (oldestAndNewestDates[0] !== minDate || oldestAndNewestDates[1] !== maxDate) {
        setOldestAndNewestDates([minDate, maxDate]);
      }
    }
  }, [filterValues, isRealData, oldestAndNewestDates]);

  return (
    <FlexContainer display="flex" flexDirection="column" gap="6px" marginTop="8px" maxWidth="100%">
      <DatePicker
        oldestAndNewestDates={oldestAndNewestDates}
        name={getFilterFieldName(nameSettings)}
        byType={activeValue?.byType}
        value={value}
        isInline={true}
        onChange={onChange}
        id={id}
        isRealData={isRealData}
      />
      <FlexContainer maxWidth="70px">
        <Button text="Сбросить" width="auto" heightSize="small" onClick={onResetFilterValues} />
      </FlexContainer>
    </FlexContainer>
  );
};

export const DateFilterActivation = memo(DateFilter);
