import { ClickAwayListener, Popover } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isEqual from 'lodash/isEqual';
import { colorPickerClassName } from 'modules/settingsContainer/ColorPicker/constants';
import { useColorValues, usePopover, useColorPickerValue } from 'modules/settingsContainer/ColorPicker/hooks';
import {
  Color,
  ColorBlock,
  ColorPickerWrapper,
  ColorSelector,
  GroupNameTextSpan,
  Groups,
  GroupsWrapper,
  Palette,
} from 'modules/settingsContainer/ColorPicker/styles';
import {
  ActivatorProps,
  ActivatorType,
  ColorClickValue,
  ColorPickerTypesInterface,
  ColorValue,
  ColorValuesByThemeType,
  ColorValueType,
  LevelNumbersType,
  PaletteValue,
  PaletteValuesByThemeType,
  PaletteValueType,
  PositionIndexType,
  ThemeColorValue,
} from 'modules/settingsContainer/ColorPicker/types';
import React, { MouseEventHandler, useCallback, useEffect, useMemo } from 'react';
import { FlexContainer } from 'styles/FlexContainer';

interface OriginCircleActivatorProps extends ActivatorProps {
  width?: string;
  height?: string;
}

export const OriginCircleActivator = ({
  width = '24px',
  height = '24px',
  onClick,
  colorValues,
  defaultBackgroundColor,
}: OriginCircleActivatorProps) => {
  const { activeThemeSchema } = useColorValues();

  const backgroundColor = useMemo(
    () =>
      typeof colorValues === 'string'
        ? colorValues
        : colorValues?.[0] || defaultBackgroundColor || activeThemeSchema[ColorVarsEnum.Level_2_btn],
    [colorValues, defaultBackgroundColor, activeThemeSchema],
  );

  return (
    <FlexContainer
      onClick={onClick}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
      borderRadius="50%"
      cursor="pointer"
      border={`1px solid var(${ColorVarsEnum.Level_5})`}
    />
  );
};

export const CircleActivator: ActivatorType = (props) => <OriginCircleActivator {...props} />;

export const LargeCircleActivator: ActivatorType = (props) => <OriginCircleActivator width="30px" height="30px" {...props} />;

const DefaultActivator: ActivatorType = ({ colorValues, onClick, defaultBackgroundColor }) => {
  const activeColors = useMemo(
    () => (typeof colorValues === 'string' ? [colorValues] : defaultBackgroundColor ? [defaultBackgroundColor] : colorValues),
    [colorValues, defaultBackgroundColor],
  );

  const colorBlocks = useMemo(() => {
    if (activeColors) {
      const [first, second, third, fourth] = activeColors,
        secondColor = second || first,
        thirdColor = third || secondColor,
        fourthColor = fourth || thirdColor;

      return [first, secondColor, thirdColor, fourthColor];
    }

    return [];
  }, [activeColors]);

  return (
    <ColorSelector onClick={onClick}>
      {colorBlocks.map((color, positionIndex) => (
        <ColorBlock key={`${color}_${positionIndex}`} positionIndex={positionIndex as PositionIndexType} color={color} />
      ))}
    </ColorSelector>
  );
};

export interface ColorPickerProps<Value extends PaletteValuesByThemeType | ColorValuesByThemeType = ColorValuesByThemeType>
  extends ColorPickerTypesInterface {
  disabled?: boolean;
  onChange?: (value: Value | null) => void;
  value?: Value | null;
  closeOnClick?: boolean;
  Activator?: ActivatorType;
  defaultActivatorBackgroundColor?: string;
}

export const ColorPicker = <Value extends PaletteValuesByThemeType | ColorValuesByThemeType = ColorValuesByThemeType>({
  disabled,
  onChange,
  value = null,
  type = 'color',
  closeOnClick = false,
  defaultActivatorBackgroundColor,
  Activator = DefaultActivator,
}: ColorPickerProps<Value>) => {
  const {
    paletteId,
    getHighPriorityColorValue,
    activePaletteColor,
    defaultThemePalette,
    getColorValues,
    groupNames,
    showGroupNames,
  } = useColorValues();

  const { popoverAnchorEl, setPopoverAnchorEl } = usePopover();
  const { activeColorPickerValue, setActiveColorPickerValue } = useColorPickerValue<Value>();

  useEffect(() => {
    setActiveColorPickerValue(value);
  }, [value, setActiveColorPickerValue]);

  const paletteGroups = useMemo(
    () => (paletteId ? Object.entries(activePaletteColor).map(([groupId, colors]) => ({ groupId, colors })) : []),
    [activePaletteColor, paletteId],
  );

  const isColorPicker = type === 'color';
  const activePaletteColorPickerValue = paletteId ? getHighPriorityColorValue(activeColorPickerValue) : null;

  const onColorSelectorClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => setPopoverAnchorEl(e.currentTarget),
    [setPopoverAnchorEl],
  );
  const onCloseMenu = useCallback(() => {
    setPopoverAnchorEl(null);
  }, [setPopoverAnchorEl]);

  const onLocalChange = useCallback(
    (newValue: Value) => {
      setActiveColorPickerValue(newValue);
      onChange && onChange(newValue);
    },
    [onChange, setActiveColorPickerValue],
  );

  const setValue = useCallback(
    <T extends ColorValueType | PaletteValueType>(value: T) => {
      if (!paletteId) return;
      const newValue = isEqual(activePaletteColorPickerValue, value) ? undefined : value;

      onLocalChange({ ...(activeColorPickerValue || {}), [paletteId]: newValue } as Value);
      closeOnClick && onCloseMenu();
    },
    [activeColorPickerValue, activePaletteColorPickerValue, closeOnClick, onCloseMenu, onLocalChange, paletteId],
  );

  const onColorClick = useCallback(
    (colorValue: ColorClickValue) =>
      isColorPicker
        ? () => {
            let value;

            if (typeof colorValue === 'number') {
              value = { type, level: colorValue };
            } else {
              const { groupId, colorId } = colorValue;
              value = { groupId, colorId, type };
            }

            setValue(value);
          }
        : undefined,
    [isColorPicker, type, setValue],
  );

  const onGroupClick = useCallback(
    (groupId?: string) =>
      isColorPicker
        ? undefined
        : () => {
            let value;

            if (groupId) {
              value = { groupId, type };
            } else {
              value = { type };
            }

            setValue(value);
          },
    [isColorPicker, type, setValue],
  );

  if (!paletteId) return null;

  return (
    <ColorPickerWrapper>
      <Activator
        defaultBackgroundColor={defaultActivatorBackgroundColor}
        colorValues={getColorValues(activeColorPickerValue)}
        onClick={disabled ? undefined : onColorSelectorClick}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={popoverAnchorEl}
        open={!!popoverAnchorEl}
        className={colorPickerClassName}
      >
        <ClickAwayListener onClickAway={onCloseMenu}>
          <Palette type={type}>
            {paletteGroups.map(({ groupId, colors }) => {
              const groupColors = Object.entries(colors),
                isActiveGroup =
                  !isColorPicker && (activePaletteColorPickerValue as PaletteValue | undefined)?.groupId === groupId,
                groupName = groupNames?.[groupId];

              return (
                <GroupsWrapper active={isActiveGroup} onClick={onGroupClick(groupId)} key={groupId}>
                  {groupName && showGroupNames && <GroupNameTextSpan>{groupName}</GroupNameTextSpan>}
                  <Groups>
                    {groupColors.map(([colorId, color]) => {
                      const isActiveColor =
                        isColorPicker && (activePaletteColorPickerValue as ColorValue | undefined)?.colorId === colorId;

                      return (
                        <Color active={isActiveColor} onClick={onColorClick({ colorId, groupId })} key={colorId} color={color} />
                      );
                    })}
                  </Groups>
                </GroupsWrapper>
              );
            })}

            <GroupsWrapper active={!isColorPicker && isEqual(activePaletteColorPickerValue, { type })} onClick={onGroupClick()}>
              {showGroupNames && <GroupNameTextSpan>Стандартная группа</GroupNameTextSpan>}
              <Groups>
                {defaultThemePalette.map((color, index) => {
                  const level = index + 1,
                    isActiveColor =
                      isColorPicker && (activePaletteColorPickerValue as ThemeColorValue | undefined)?.level === level;

                  return (
                    <Color
                      active={isActiveColor}
                      onClick={onColorClick(level as LevelNumbersType)}
                      key={`${color}_${index}`}
                      color={color}
                    />
                  );
                })}
              </Groups>
            </GroupsWrapper>
          </Palette>
        </ClickAwayListener>
      </Popover>
    </ColorPickerWrapper>
  );
};
